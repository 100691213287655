import React, { useState } from 'react';
import { Link, graphql, navigate } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { MDBContainer, MDBCarousel, MDBCarouselItem, MDBView, MDBCarouselCaption, MDBCarouselInner } from "mdbreact";
import { Tab } from 'semantic-ui-react'

const ServiceTemplate = ({ data }) => {
    const [value, setValue] = React.useState(0);
    const panes = []
    data.strapiService.feature.map(item => {
      let count = -1
      let title = data.strapiService.title
      title = title.toLowerCase()
      if (title.slice(-1) === "s") {
        title = title.substring(0, title.length - 1);
      }
      panes.push({
        menuItem: item.title,
        render: () => 
          <Tab.Pane attached={false} className="tabHelper" key={item.id}>
            <h3 className="font-weight-bold dark-grey-text mt-4">
                {item.description} | <a className="contactLink" onClick={() => navigate("/#contact")}>contact us</a> for your {title}
            </h3>
    <MDBContainer key={item.id} className="containerHelper">
    <MDBCarousel
        activeItem={1}
        length={item.images.length}
        showControls={true}
        showIndicators={true}
        className="z-depth-1"
        slide
        key={item.id}
      >
        <MDBCarouselInner>
        {
          item.images.map(img => {
            count = count + 1
            return(
            <MDBCarouselItem key={(count % item.images.length) + 1} itemId={(count % item.images.length) + 1}>
            <MDBView>
                <Img
                    fluid={img.image.childImageSharp.fluid}
                    className="img-fluid"
                />
            </MDBView>
          </MDBCarouselItem>
            )
          })
        }
        </MDBCarouselInner>
      </MDBCarousel>
    </MDBContainer>
          </Tab.Pane>,
      })
    })
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
        <>
            <Layout navInfo={true} >
              <MDBContainer tag="section"  className="text-center my-5 serviceSection" >
                <h2 className="h1-responsive font-weight-bold mb-0 pt-md-5 pt-5 black-text">{data.strapiService.title}</h2>
                <h5 className="h5-responsive font-weight-bold mb-0 pt-md-5 pt-5 black-text">{data.strapiService.description}</h5>
                <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
              </MDBContainer>
            </Layout>
    </>
    )
}

export default ServiceTemplate;


export const query = graphql`
query ServiceTemplate($id: String!) {
    strapiService(id: {eq: $id}) {
        description
        feature {
          description
          id
          title
          images {
            id
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        title
        mainImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
  }
  `